.wrapper {
    width: 600px;
    padding: 2rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 18px;
    letter-spacing: 0.3px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
}

.text {
    font-size: 15px;
    letter-spacing: 0.3px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.wrapperButton {
    display: flex;
    margin: 1rem auto 0;
}

.wrapperButton .button:first-child() {
    margin-right: 1rem;
}

.button {
    width: 50px;
    font-size: 13px;
    color: #2a2a2a;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 8px 11px;
    background-color: transparent;
    margin-left: 10px;
    border: 1px solid #c1c1c1;
    outline: none;
    letter-spacing: 1px;
}

.button:hover {
    color: #ffffff;
    background-color: #ff6b08;
    border: 0;
}