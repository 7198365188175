.buttonsContainer {
    width: 100%;
    display: flex;
    align-items: center;
}

.checkButton {
    font-size: 13px;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 8px 11px;
    background-color: #ff6b08;
    border: 0;
    outline: none;
    letter-spacing: 0.3px;
}

.checkButton:hover {
    background-color: #e96105;
}

.buttonWithSvg {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #6b6f7a;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    padding: 5px 11px;
    background-color: transparent;
    margin-left: 10px;
    border: 1px solid #c1c1c1;
    outline: none;
}

.disableButtonWithSvg {
    composes: buttonWithSvg;
    color: #e0e2e6;
    border: 1px solid#e0e2e6;
}

.buttonWithSvg:hover {
    border: 1px solid #1b4371;
}

.disableButtonWithSvg:hover {
    border: 1px solid #e0e2e6;
}

.svg {
    margin-right: 0.5rem;
    fill: #6b6f7a;
}

.disableSvg {
    margin-right: 0.5rem;
    fill: #e0e2e6;
}

@media (max-width: 1200px) {
    .checkButton {
        font-size: 10px;
        padding: 4px 6px;
    }

    .buttonWithSvg {
        font-size: 10px;
        padding: 2.5px 6px;
    }

    .svg {
        width: 12px;
        height: 12px;
    }

    .disableSvg {
        width: 12px;
        height: 12px;
    }
}